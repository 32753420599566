<script>
import Layout from "../../layouts/auth";

import { mapActions } from "vuex";
import Swal from 'sweetalert2'
import appConfig from "@/app.config";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

/**
 * Login component
 */
export default {
	setup() {
		return { v$: useVuelidate() };
	},
	page: {
		title: "Login",
		meta: [
			{
				name: "description",
				content: appConfig.description,
			},
		],
	},
	components: {
		Layout,
	},
	data() {
		return {
			username: "seller",
			password: "password",
			submitted: false,
			authError: null,
			tryingToLogIn: false,
			isAuthError: false,
			change_password_modal:false,
			selected: 'register',
			pl:{
				username: null,
				email_address: null,
				contact_number: null,
				address: null,
				birthday: null,
				password: null,
				confirm_password: null,
				role: 'buyer',
				first_name: null,
				last_name: null,
			},
			showPassword:false,
			showConfirmPassword:false,
		};
	},
	validations: {
		username: {
			required: helpers.withMessage("username is required", required),
		},
		password: {
			required: helpers.withMessage("Password is required", required),
		},
	},
	computed: {
		notification() {
			return this.$store ? this.$store.state.notification : null;
		},
	},
	methods: {
        ...mapActions('auth', {
            authAdmin: 'loginSeller',
            newUser: 'registerAccount',
        }),
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        toggleShow2() {
            this.showConfirmPassword = !this.showConfirmPassword;
        },
		gotoLogin(){
			this.$router.push({path: "/login"});
		},
        async registerUser(){
			var pl = {
				username : this.pl.username,
				email_address : this.pl.email_address,
				// contact_number : this.pl.contact_number,
				// address : this.pl.address,
				birthday : this.pl.birthday,
				password : this.pl.password,
				confirm_password : this.pl.confirm_password,
				role : this.pl.role,
				first_name : this.pl.first_name,
				last_name : this.pl.last_name,
			}
            const res = await this.newUser(pl);
            if (res.status == 200|| res.status == 'success') {
                Swal.fire({
                    title: "Success",
                    html: `Registration Successful`,
                    type: "success",
                    icon: "success",
                    padding: "2em",
                });
				setTimeout(() => {
					this.$router.push({path:"/login"});
				}, 3000);
            }else{
                Swal.fire({
                    html: res,
                    type: "error",
                    icon: "error",
                    padding: "2em",
                });
            }
        },
	},
	mounted() {},
};
</script>

<template>
	<Layout>
		<div class="row justify-content-center">
			<div class="col-8 register-container">
				<div class="card overflow-hidden">
					<div class="card-body pt-0">
						<div class="text-center  mt-4">
							<button class="btn btn-info" @click="gotoLogin()">Ready to Login?</button>
						</div>
						<b-form class="p-2"  v-if="selected == 'register'">
							<div class="col-12">
								<div class="row">
									<div class="col-12">
										<b-form-group
											class="mb-3"
											id="input-group-1"
											label="Username"
											label-for="input-1"
										>
											<b-form-input
												id="input-1"
												v-model="pl.username"
												type="text"
												placeholder="Enter username"
											></b-form-input>
										</b-form-group>
									</div>
									<!-- <div class="col-6">
										<b-form-group
											class="mb-3"
											id="input-group-1"
											label="Role"
											label-for="input-1"
										>
											<select class="form-control" v-model="pl.role">
												<option selected >---Select Role---</option>
												<option value="buyer">Buyer</option>
												<option value="seller">Seller</option>
											</select>
										</b-form-group>
									</div> -->
								</div>
							</div>
							<b-form-group
								class="mb-3"
								id="input-group-1"
								label="Email address"
								label-for="input-1"
							>
								<b-form-input
									id="input-1"
									v-model="pl.email_address"
									type="email"
									placeholder="example@gmail.com"
								></b-form-input>
							</b-form-group>
							<!-- <b-form-group
								class="mb-3"
								id="input-group-1"
								label="Address"
								label-for="input-1"
							>
								<b-form-input
									id="input-1"
									v-model="pl.address"
									type="text"
									placeholder="Enter address"
								></b-form-input>
							</b-form-group> -->
							<div class="col-12">
								<div class="row birthday">
									<div class="col-12">
										<b-form-group
											class="mb-3"
											id="input-group-1"
											label="Birthday"
											label-for="input-1"
										>
											<b-form-input
												id="input-1"
												v-model="pl.birthday"
												type="date"
											></b-form-input>
										</b-form-group>
									</div>
									<!-- <div class="col-6">
										<b-form-group
											class="mb-3"
											id="input-group-1"
											label="Contact Number"
											label-for="input-1"
										>
											<b-form-input
												id="input-1"
												v-model="pl.contact_number"
												type="text"
												placeholder="Enter contact number"
											></b-form-input>
										</b-form-group>
									</div> -->
								</div>
							</div>
							<div class="col-12">
								<div class="row name">
									<div class="col-6">
										<b-form-group
											class="mb-3"
											id="input-group-1"
											label="First Name"
											label-for="input-1"
										>
											<b-form-input
												id="input-1"
												v-model="pl.first_name"
												type="text"
												placeholder="Enter First Name"
											></b-form-input>
										</b-form-group>
									</div>
									<div class="col-6">
										<b-form-group
											class="mb-3"
											id="input-group-1"
											label="Last Name"
											label-for="input-1"
										>
											<b-form-input
												id="input-1"
												v-model="pl.last_name"
												type="text"
												placeholder="Enter Last Name"
											></b-form-input>
										</b-form-group>
									</div>
								</div>
							</div>
							<!-- <b-form-group
								class="mb-3"
								id="input-group-2"
								label="Password"
								label-for="input-2"
							>
								<b-form-input
									id="input-2"
									v-model="pl.password"
									type="password"
									placeholder="Enter password"
								></b-form-input>
								
							</b-form-group> -->
							
							<b-form-group class="mb-3" id="input-group-2" label="Password" label-for="password">
                                <div class="d-flex">
                                    <b-form-input v-if="showPassword" class="input eye-width" id="password"
                                        v-model="pl.password" type="text" placeholder="Enter your password" :class="{
                                            'is-invalid':
                                                submitted && v$.password.$error,
                                        }">
                                    </b-form-input>
                                    <b-form-input v-else class="input eye-width" id="password" v-model="pl.password"
                                        type="password" placeholder="Enter your password" :class="{
                                            'is-invalid':
                                                submitted && v$.password.$error,
                                        }">
                                    </b-form-input>
									<span @click="toggleShow"  class="icon is-small is-right eye-btn">
										<i class="fas"
											:class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
									</span>
                                </div>
                            </b-form-group>
							<b-form-group class="mb-3" id="input-group-2" label="Confirm Password" label-for="password">
                                <div class="d-flex">
                                    <b-form-input v-if="showConfirmPassword" class="input eye-width" id="password2"
                                        v-model="pl.confirm_password" type="text" placeholder="Enter your password" :class="{
                                            'is-invalid':
                                                submitted && v$.password.$error,
                                        }">
                                    </b-form-input>
                                    <b-form-input v-else class="input eye-width" id="password2" v-model="pl.confirm_password"
                                        type="password" placeholder="Enter your password" :class="{
                                            'is-invalid':
                                                submitted && v$.password.$error,
                                        }">
                                    </b-form-input>
									<span @click="toggleShow2"  class="icon is-small is-right eye-btn">
										<i class="fas"
											:class="{ 'fa-eye-slash': showConfirmPassword, 'fa-eye': !showConfirmPassword }"></i>
									</span>
                                </div>
                            </b-form-group>
							<!-- <b-form-group
								class="mb-3"
								id="input-group-2"
								label="Confirm Password"
								label-for="input-2"
							>
								<b-form-input
									id="input-2"
									v-model="pl.confirm_password"
									type="password"
									placeholder="Confirm password"
								></b-form-input>
							</b-form-group> -->
							<div class="mt-3 d-grid">
								<b-button
									type="submit"
									variant="warning"
									class="btn-block"
									@click="registerUser()"
									>Register</b-button
								>
							</div>
						</b-form>
					</div>
					<!-- end card-body -->
				</div>
				<!-- end row -->
			</div>
			<!-- end col -->
		</div>
		<!-- end row -->
	</Layout>
</template>
<style scoped>
.register-container{
	width: 100%;
	max-width: 600px;
}
.eye-width {
    border-radius: 0px !important;
}

.eye-btn {
    background-color: transparent;
    border: none;
    border-radius: 0px !important;
	position: absolute;
	right: 40px;
	margin-top: 8px;
	color: rgb(95, 95, 95) !important;
}
@media(max-width:425px){
	.birthday,
	.name{
		display: flex;
		flex-direction: column;
	}
	.birthday > div,
	.name > div{
		width:100%;
	}
}
</style>